import React from 'react';

const Close = (props) => (
  <svg {...props} width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g className="fill" transform="translate(-317.000000, -279.000000)" fill="#1D1D1D" fillRule="nonzero">
        <path d="M328.180338,288.500198 L335.651852,281.028372 C336.116049,280.564552 336.116049,279.811983 335.651852,279.348162 C335.187655,278.883946 334.435909,278.883946 333.971712,279.348162 L326.500198,286.819988 L319.028288,279.348162 C318.564091,278.883946 317.812345,278.883946 317.348148,279.348162 C316.883951,279.811983 316.883951,280.564552 317.348148,281.028372 L324.820058,288.500198 L317.348148,295.972024 C316.883951,296.435844 316.883951,297.188413 317.348148,297.652234 C317.580246,297.883946 317.88443,298 318.188218,298 C318.492006,298 318.796189,297.883946 319.028288,297.651838 L326.500198,290.180012 L333.971712,297.651838 C334.203811,297.883946 334.507994,298 334.811782,298 C335.11557,298 335.419754,297.883946 335.651852,297.651838 C336.116049,297.188017 336.116049,296.435448 335.651852,295.971628 L328.180338,288.500198 Z" />
      </g>
    </g>
  </svg>
);

export default Close;
