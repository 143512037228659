import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import styles from './Modal.module.scss';
import Close from '../Icons/Close';
import './Modal.scss';

const MODAL_IS_OPEN = 'Modal--is-open';
const BODY_NODE = typeof window !== 'undefined' ? window.document.querySelector('body') : null;

const Portal = ({ children }) => {
  const portalRoot = typeof document !== 'undefined' ? document.getElementById('portal') : null;
  if (portalRoot) {
    return ReactDOM.createPortal(children, portalRoot);
  }
  return null;
};

const Modal = ({ children, isOpen, title, closeHandler, fullScreen, className }) => {
  const classNames = [styles.modal];

  useEffect(() => {
    if (fullScreen) {
      if (isOpen) BODY_NODE.classList.add(MODAL_IS_OPEN);
      else BODY_NODE.classList.remove(MODAL_IS_OPEN);
    }
  }, [isOpen]);

  if (className) classNames.push(className);
  if (fullScreen) classNames.push(styles.fullScreen);

  return (
    <Portal>
      <ReactCSSTransitionGroup
        transitionName="modal"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        {isOpen && (
          <aside
            className={classNames.join(' ')}
            role="dialog"
            aria-modal="true"
          >
            <button
              type="button"
              className={styles.close}
              onClick={closeHandler}
              aria-label="Schließen"
            >
              <Close />
            </button>
            <div className={`${styles.inner} ${fullScreen ? styles.innerFullScreen : ''}`}>
              {title && (
                <h3 className={styles.title}>{title}</h3>
              )}
              {children}
            </div>
          </aside>
        )}
      </ReactCSSTransitionGroup>
    </Portal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  closeHandler: PropTypes.func.isRequired,
  title: PropTypes.string,
  fullScreen: PropTypes.bool,
  className: PropTypes.string,
};

Modal.defaultProps = {
  title: null,
  fullScreen: false,
  className: null,
};

export default Modal;
