import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
// import LazyLoad from 'react-lazyload';
import './index.scss';
import SEO from '../container/Seo/Seo';
import Layout from '../presentational/Layout/Layout';
import ModalUpdate from '../presentational/ModalUpdate/ModalUpdate';
import Stage from '../presentational/Stage/Stage';
import FamilienBetrieb from '../presentational/Icons/FamilienBetrieb';
import Regionales from '../presentational/Icons/Regionales';
import Handmade from '../presentational/Icons/Handmade';
import Banner from '../presentational/Banner/Banner';
import MenuSuggestion from '../presentational/MenuSuggestion/MenuSuggestion';
import useScroll from '../hooks/scroll';

function IndexPage({ location }) {
  useScroll();
  return (
    <>
      <Layout pathname={location.pathname}>
        <SEO
          title="Familien Betrieb"
        />

        <Stage />

        {/* <section id="christmas" className="section">
          <div className="container">
            <div className="row">
              <div className="col col--lg-8">
                <h1>Weihnachtsmenü</h1>
                <p>Liebe Kunden, vielen Dank für das große Interesse an unseren Weihnachtsmenüs.</p>
                <p className="text--primary"><strong>Aufgrund der großen Nachfrage, können wir leider keine weiteren Bestellungen der Weihnachtsmenüs mehr annehmen.</strong></p>
                <p>Auch dieses Jahr gibt es wieder unser bekanntes Weihnachtsmenü. Alle Gerichte können Sie entweder am 22.12. von 08:00–18:00 Uhr, oder am 23.12. von 07:00–12:00 Uhr bei uns abholen.</p>
                <Link to="/weihnachts-menue" className="button">Jetzt vorbestellen</Link>
                <p>
                  Die Frist für die Vorbestellung ist leider abgelaufen. Wir bedanken uns auch dieses Jahr wieder für die zahlreichen Bestellungen und
                  wünschen Ihnen und Ihren Liebsten ein frohes Weihnachtsfest und einen guten Rutsch ins neue Jahr.
                </p>
              </div>
              <div className="col col--lg-4">
                <a href="/pdf/Sauer_Weihnachtsflyer_2022.pdf" target="_BLANK">
                <img
                  className="christmas__image"
                  src="/images/christmas/christmas-flyer.jpg"
                  alt="Weihnachtsmenü Flyer"
                />
                <span className="christmas__text">Fyler Download (PDF)</span>
                </a>
              </div>
            </div>
          </div>
        </section> */}

        <section id="catering" className="section">
          <div className="container">
            <div className="row">
              <div className="col col--lg-8">
                <h1>Catering & Ladenlokal am Sorpesee</h1>
                <p>Unser Catering und Partyservice besteht bereits seit 2001 und befindet sich direkt unterhalb des Sorpedammes in Sundern - Langscheid im Sauerland. Zusätzlich zu unserem Catering bieten wir Ihnen freitags und samstags regionale Fleisch- und Wurstwaren an, mit denen Sie sich von unserer Qualität überzeugen können.</p>
                <Link to="/online-menue-planer" className="button button--ghost IndexPage__quicklink">Online Menü-Planer</Link>
                <p>Das Wohl der Tiere liegt uns sehr am Herzen. Unser Schweine- und Hähnchenfleisch beziehen wir von der Firma Merte in Schmallenberg. Unsere Rinder kommen vom Bio-Bauernhof Meisterjahn direkt um die Ecke. Auch Bio-Weidehähnchen von den Schickermoosern können Sie bei uns auf Vorbestellung erwerben. Unser Wildfleisch kommt von heimischen Jägern.</p>
                <p>Wir arbeiten nach dem Motto „Aus der Region, für die Region“.</p>
              </div>
              <div className="col col--lg-4">
                <div className="IndexPage__icons">
                  <FamilienBetrieb className="IndexPage__icon" />
                  <Regionales className="IndexPage__icon" />
                  <Handmade className="IndexPage__icon" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Banner />

        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col col--lg-6">
                <span className="h1">„Qualität, Frische und Preisleistung sind dabei unser höchstes Gebot.“</span>
              </div>
              <div className="col col--lg-6">
                <p>Von Geburtstagsfeiern bis Abifeten, vom Grünkohl- essen bis zum großen Barbecue. Egal, was Sie auch feiern, von 30 bis 1.000 Personen sind wir für Sie da und sorgen dafür, dass aus Ihrer Feier ein gelungenes Event wird.</p>
                <p>Hier finden Sie ein paar Beispiele aus unserem Angebot. Sehr gerne fertigen wir für Sie auch andere Speisen an und beraten Sie ganz unverbindlich bei einem persönlichen Gespräch. Mit unserem <Link to="/online-menue-planer">Online Menü-Planer</Link> können Sie sich auch gerne Ihr Wunsch-Menü zusammen stellen.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="ImageSection">
            <div className="ImageSection__first">
              <picture>
                <source srcSet="/images/tatjana-sauer-beim-kochen-lg.jpg" media="(min-width: 768px)" />
                <img className="ImageSection__image" src="/images/tatjana-sauer-beim-kochen-sm.jpg" alt="Tatjana Sauer beim Kochen" />
              </picture>
            </div>
            <div className="ImageSection__second">
              <picture>
                <source srcSet="/images/karl-heinz-sauer-beim-kochen-lg.jpg" media="(min-width: 768px)" />
                <img className="ImageSection__image" src="/images/karl-heinz-sauer-beim-kochen-sm.jpg" alt="Karl-Heinz Sauer beim Kochen" />
              </picture>
            </div>
          </div>
        </section>

        <section id="menu-suggestions" className="section">
          <div className="container">
            <h2 className="h1 headline--section">Unsere Menü Vorschläge</h2>

            <div className="row">
              <div className="col col--lg-6 col--no-bottom-margin">
                <MenuSuggestion icon="/images/icons/schwein.svg">
                  <ul>
                    <li>Schweinefilet in Pfeffer-Cognac-Soße, Kartoffelgratin und Gemüseplatte</li>
                    <li>Schweinekarree in Apfel-Curry-Soße mit Walnüssen, dazu Spätzle und Kaisergemüse</li>
                    <li>Spießbraten mit Bratkartoffeln und grünem Salat</li>
                    <li>Minihaxen mit Püree und Sauerkraut</li>
                    <li>Backschinken mit Rosmarinkartoffeln, Krautsalat und Kräutersoße</li>
                    <li>Schweinegeschnetzeltes mit Spätzle und Bohnensalat</li>
                    <li>Wirsingroulade mit Buttersoße und Petersilienkartoffeln</li>
                    <li>Partyschnitzel mit verschiedenen Soßen, Gratin und Bauernsalat</li>
                  </ul>
                </MenuSuggestion>
                <MenuSuggestion icon="/images/icons/rind.svg">
                  <ul>
                    <li>Sauerländer Schmorbraten mit Petersilienkartoffeln und Rotkohl</li>
                    <li>Rheinischer Sauerbraten mit Klößen und Speckbohnen</li>
                    <li>Rumpsteak mit Bratkartoffeln und Salat</li>
                    <li>Rinderrouladen, Petersilienkartoffeln und Rotkohl</li>
                  </ul>
                </MenuSuggestion>
                <MenuSuggestion icon="/images/icons/fisch.svg">
                  <ul>
                    <li>Stremellachs auf Blattspinat mit Petersilienkartoffeln und Zitronenbuttersoße</li>
                  </ul>
                </MenuSuggestion>
              </div>
              <div className="col col--lg-6">
                <MenuSuggestion icon="/images/icons/wild.svg">
                  <ul>
                    <li>Wildragout mit Petersilienkartoffeln, Rotkohl, Birne und Preiselbeeren</li>
                    <li>Wildschweinkeule mit Knödeln, Speckbohnen, Birne und Preiselbeeren</li>
                  </ul>
                </MenuSuggestion>
                <MenuSuggestion icon="/images/icons/huhn.svg">
                  <ul>
                    <li>Hähnchenfilet mediterran oder fruchtig mit Gemüsereis und Salat</li>
                    <li>Puten-Thai-Curry mit mediterranen Pfannen- gemüse und Reis</li>
                    <li>Grillhähnchen mit Rosmarinkartoffeln und Kräutersoße</li>
                    <li>Hähnchenfilet auf Blattspinat mit Tagliatelle und hausgemachtem Pesto</li>
                  </ul>
                </MenuSuggestion>
                <MenuSuggestion>
                  <ul>
                    <li>Suppen aller Art</li>
                    <li>Pastagerichte z.B. Cannelloni mit Ricotta- Spinat-Füllung</li>
                    <li>vegetarische Gerichte</li>
                    <li>Fingerfood: Wraps in verschiedenen Variationen, Mini-Hamburger, Antipasti, usw.</li>
                    <li>Salate: Bauernsalat, mediterraner Nudelsalat, usw.</li>
                    <li>Desserts: Sommer-Tiramisu, Herrencreme nach Omaʼs Rezept, Spaghettieis Dessert</li>
                  </ul>
                </MenuSuggestion>
              </div>
            </div>
          </div>
        </section>

        <section className="section section--highlighted">
          <div className="container">
            <h2 className="h1 headline--section">Außerdem verleihen wir:</h2>
            <ul className="IconList">
              <li className="IconList__item">
                <img className="IconList__image" src="/images/icons/geschirr.svg" alt="Geschirr & Besteck" />
                <span className="IconList__title">Geschirr & Besteck</span>
              </li>
              <li className="IconList__item">
                <img className="IconList__image" src="/images/icons/grill.svg" alt="Grill" />
                <span className="IconList__title">Grill</span>
              </li>
              <li className="IconList__item">
                <img className="IconList__image" src="/images/icons/chafing-dish.svg" alt="Chafing Dish" />
                <span className="IconList__title">Chafing-Dish</span>
              </li>
              <li className="IconList__item">
                <img className="IconList__image" src="/images/icons/speisetransportbehaelter.svg" alt="Speisetransportbehaelter" />
                <span className="IconList__title">Speisetransportbehälter</span>
              </li>
              <li className="IconList__item">
                <img className="IconList__image" src="/images/icons/kuehlanhaenger.svg" alt="Kühlanhänger" />
                <span className="IconList__title">Kühlanhänger</span>
              </li>
            </ul>
          </div>
        </section>

        <section id="quality-information" className="section">
          <div className="container">
            <h2 className="h1">Qualitätsinfo</h2>
            <div className="row">
              <div className="col col--lg-6">
                <h3>Schweinefleisch</h3>
                <p>Premiumqualität erzeugt man am besten vor Ort, deshalb beziehen wir unser Schweinefleisch aus nächster Umgebung von der Firma Merte aus Schmallenberg.</p>
                <ul>
                  <li>Die Aufzucht der Tiere erfolgt unter optimalen Bedingungen bis zu einem Schlachtgewicht von 100 kg auf ausgesuchten bäuerlichen Familienbetrieben.</li>
                  <li>Gefüttert wird auf Basis des heimischen Anbaus, Getreide und Eiweißergänzungsfutter. Auf Ab- fallfuttermittel wie Tiermehl und Ähnliches wird verzichtet.</li>
                  <li>Wir garantieren Ihnen eine lückenlose Herkunftsgarantie. Ihr Vorteil: Das Fleisch ist in der Farbe kräftiger, zarter, länger haltbar und durch bessere Fleisch- und Fettstabilität geringer im Bratverlust.</li>
                </ul>
                <h3>Ardeyer Landhähnchen</h3>
                <p>Die Hühnchen vom Hähnchenhof zur Nieden aus Fröndenberg werden fast doppelt so als wie ihre Artgenossen in der industriellen Tiermast – und das merkt man mehr als deutlich an der Fleischqualität! Der Hähnchenhof zur Nieden setzt auf Regionalität und Natürlichkeit, so wie wir.</p>
              </div>
              <div className="col col--lg-6">
                <h3>Bio-Weidehähnchen Schickermooser</h3>
                <p>Die Schickermooser vom Weidehof in Oelinghausen liefern uns Deutschlands erste Bio Weidehähn- chen. Die Hühner führen ein artgerechtes und gesundes Leben. Sie leben in kleinen Gruppen auf der Weide, wo ihre Ställe täglich in Handarbeit auf frisches Gras versetzt werden. Sie werden zwei- bis dreimal so alt wie ihre konventionellen Artgenossen und genießen uneingeschränkt das aktive Ökosystem Graslandschaft. Das Fleisch der Schickermooser Weidehähnchen können Sie bei uns bestellen!</p>
                <h3>Bio-Rindfleisch</h3>
                <p>Bei uns bekommen Sie Ihr Rindfleisch direkt vom Bio-Bauernhof Meisterjahn um die Ecke.</p>
                <ul>
                  <li>Die Rinder wachsen im Familienband auf und fressen frisches Gras und Heu. Der Bauernhof wird nach Bio- Vorschriften bewirtschaftet und regelmäßig von einem unabhängigen Unterneh- men überwacht. Außerdem legt Familie Meister- jahn großen Wert auf einen respektvollen Umgang mit den Tieren.</li>
                </ul>
                <p>Wer Fleisch von glücklichen Rindern kauft, der bringt gutes Karma für sich, die Tiere und den Rest der Welt!</p>
              </div>
            </div>
            <img className="IndexPage__bioIcon" src="/images/bio-siegel-eg-oeko-vo-deutschland.png" alt="Bio-Siegel EG Öko VO Deutschland" />
          </div>

          <div className="sectionImage">
            <picture>
              <source srcSet="/images/karl-heinz-sauer-lg.jpg" media="(min-width: 1200px)" />
              <source srcSet="/images/karl-heinz-sauer-md.jpg" media="(min-width: 576px)" />
              <img className="sectionImage__image" src="/images/karl-heinz-sauer-sm.jpg" alt="Karl-Heinz Sauer" />
            </picture>
          </div>
        </section>

        <section id="butchers" className="section">
          <div className="container">
            <div className="row">
              <div className="col col--lg-6">
                <h2 className="h1">Ladenlokal</h2>
                <p>Zu dem Catering und Partyservice hat unser Fleischerei-Ladenlokal für Sie Freitags und Samstags geöffnet. Hier finden Sie frische regionale Fleisch- und Wurstwaren. Qualität die Sie schmecken können. Hierbei legen wir großen Wert auf die vertrauensvolle Zusammenarbeit mit unseren Lieferanten aus der Region.</p>
              </div>
              <div className="col col--lg-6">
                <h2>Öffnungszeiten</h2>
                <h4 className="m-sm">Donnerstags</h4>
                <p>14:30 - 18:00 Uhr</p>
                <h4 className="m-sm">Freitags</h4>
                <p>8.00 - 18.00 Uhr</p>
                <h4 className="m-sm">Samstags</h4>
                <p>8.00 - 13.00 Uhr</p>
              </div>
            </div>
          </div>
        </section>

        <section className="section section--highlighted">
          <div className="container">
            <div className="sectionTeaser">
              <div className="sectionTeaser__icon">
                <img src="/images/icons/verpackung.svg" alt="Verpackung" />
              </div>
              <div>
                <h4>Wir möchten unsere Umwelt schützen und Sie können uns dabei helfen!</h4>
                <p>Sie haben die Möglichkeit Ihre eigenen Frischhalteboxen von Zuhause mitzubringen. Wir befüllen diese dann unter hygienischen Voraussetzungen mit Ihren Produktwünschen. Außerdem arbeiten wir mit Bio-Plastik und Palmblattgeschirr, welches beides kompostierbar ist.</p>
              </div>
            </div>
          </div>
        </section>

        <ModalUpdate />
      </Layout>
    </>
  );
}

IndexPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default IndexPage;
